import { Button, FormLabel, Heading, Input, InputGroup, InputLeftAddon } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Manufacturer } from "../../models/manufacturer";
import { useForm } from '@tanstack/react-form'
import { Textarea, FormControl } from '@chakra-ui/react'
import { useFetch } from "../../contexts/FetchContext";
import { FormModal } from "../../components/Helpers/FormModal";
import { Keycard } from "../../models/keycard";
import { useFormKeyBindings } from "../../components/Helpers/useFormKeyBindings";

export const EditKeycard = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [manufacturer, setManufacturer] = useState<Manufacturer>();
  const [keycard, setKeycard] = useState<Keycard>();

  const { id } = useParams();
  const navigate = useNavigate()
  const { backend } = useFetch();

  const refetch = () => {
    if (id) {
      setLoading(true)
      backend.keycards.getById(parseInt(id))
        .then((keycard) => {
          setKeycard(keycard)
          setLoading(false)
        }).catch((error) => {
          console.log(error)
        });
      backend.manufacturers.get()
        .then((data) => {
          /* Må FIKSES */
          const keyCardManufacturer = data.find(manufacturer => manufacturer.name === "Keycard")
          if (keyCardManufacturer) {
            setManufacturer(keyCardManufacturer)
          } else {
            onClose()
          }
          setLoading(false)
        });
    }
  }

  useEffect(() => {
    refetch()
  }, []);

  const onClose = () => {
    navigate(-1);
  };

  const form = useForm({
    defaultValues: {
      type: keycard?.type || '',
      description: keycard?.description || '',
      cardId: keycard?.cardId || '',
      pin: keycard?.pin || '',
    },
    onSubmit: async (values) => {
      if (await form.validateAllFields("submit")) {
        const updated_keycard: Keycard = {
          description: values.description,
          rentStatus: null,
          id: keycard!.id,
          cardId: values.cardId,
          type: values.type,
          pin: values.pin,
          deleted: keycard!.deleted,
          rentHistory: [],
        }
        backend.keycards.update(
          updated_keycard
        ).then((response) => {
          onClose();
        }).catch((error) => {
          console.log(error)
        });
      }
    }
  })

  useFormKeyBindings({
    handleSubmit: form.handleSubmit,
    handleClose: onClose,
    canSubmit: form.state.canSubmit,
    isSubmitting: form.state.isSubmitting
  });


  const FormFields = [
    (
      <form.Field
        name="cardId"
        children={field => {
          return (
            <FormControl isInvalid={field.state.meta.errors.length > 0}>
              <FormLabel>Card ID</FormLabel>
              <InputGroup>
                <InputLeftAddon children="Kort" />
                <Input
                  autoFocus
                  variant='filled'
                  required
                  name={field.name}
                  value={field.state.value}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                  placeholder="Card ID"
                />
              </InputGroup>

            </FormControl>
          )
        }}
      />
    ),
    (
      <form.Field
        name="type"
        children={field => {
          return (
            <FormControl>
              <FormLabel>Type</FormLabel>
              <Input
                variant='filled'
                required
                name={field.name}
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
                placeholder="Type"
              />
            </FormControl>
          )
        }}
      />
    ),
    (
      <form.Field
        name="pin"
        onChange={
          (value) => {
            return !value || value.length === 0
              ? 'Pin is required'
              : undefined
          }
        }
        children={field => {
          return (
            <FormControl isInvalid={field.state.meta.errors.length > 0}>
              <FormLabel>Pin</FormLabel>
              <Input
                variant='filled'
                required
                name={field.name}
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
                placeholder="Pin"
              />
            </FormControl>
          )
        }}
      />
    ), (
      <form.Field
        name="description"
        children={field => {
          return (
            <FormControl>
              <FormLabel>Desciption</FormLabel>
              <Textarea name="description" value={field.state.value} onChange={(e) => field.handleChange(e.target.value)}></Textarea>
            </FormControl>
          )
        }}
      />
    )
  ]

  const SubmitButton = () => {
    return (
      <Button colorScheme='blue' isLoading={form.state.isSubmitting} type="submit" disabled={!form.state.canSubmit}>
        Update
      </Button>
    )
  }

  const Header = () => {
    return (
      <>
        <Heading size='xl' mt={5}>New Keycard</Heading>
      </>
    )
  }

  return (
    <FormModal
      isLoading={manufacturer && keycard ? true : false}
      headerElements={[<Header />]}
      form={form}
      formFields={FormFields}
      SubmitButton={<SubmitButton />}
      onClose={onClose}
    />
  )
}
