import { useEffect } from 'react';

interface FormKeyBindingsProps {
  handleSubmit?: () => void;
  handleClose: () => void;
  canSubmit?: boolean;
  isSubmitting?: boolean;
  searchInputRef?: React.RefObject<HTMLInputElement>;
  handleRent?: () => void;
  handleEdit?: () => void;
  handleDelete?: () => void;
  isSearchFocused?: boolean;
}

export const useFormKeyBindings = ({
  handleSubmit,
  handleClose,
  canSubmit,
  isSubmitting,
  searchInputRef,
  handleRent,
  handleEdit,
  handleDelete,
  isSearchFocused
}: FormKeyBindingsProps) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // Search with Ctrl+F
      if (event.ctrlKey && event.code === 'KeyF' && searchInputRef?.current) {
        event.preventDefault();
        searchInputRef.current.focus();
        return;
      }
      
      // Submit with Enter only when search is not focused
      if (event.code === 'Enter' && handleSubmit && canSubmit && !isSubmitting && !isSearchFocused) {
        event.preventDefault();
        handleSubmit();
      }
      
      // Close with Escape
      if (event.code === 'Escape') {
        event.preventDefault();
        handleClose();
      }

      // Rent/Return with R
      if (event.ctrlKey && event.code === 'KeyR' && handleRent) {
        event.preventDefault();
        handleRent();
      }

      // Edit with E
      if (event.ctrlKey && event.code === 'KeyE' && handleEdit) {
        event.preventDefault();
        handleEdit();
      }

      // Delete/Restore with Delete
      if (event.code === 'Delete' && handleDelete) {
        event.preventDefault();
        handleDelete();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleSubmit, handleClose, canSubmit, isSubmitting, searchInputRef, handleRent, handleEdit, handleDelete]);
};