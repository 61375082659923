import { Center, Heading, Modal, ModalContent, Spinner, Stack, VStack, HStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation, Outlet } from "react-router-dom";
import { Text } from '@chakra-ui/react';
import { useFetch } from "../../contexts/FetchContext";
import { ItemModal } from "../../components/ItemModal";
import { Locker, lockerToString } from "../../models/lockers";
import { useFormKeyBindings } from "../../components/Helpers/useFormKeyBindings";

export const LockerModal = () => {
  const [locker, setLocker] = useState<Locker | null>(null);
  const navigate = useNavigate();
  const { backend } = useFetch();
  const { id } = useParams();
  const location = useLocation();

  const onClose = () => {
    navigate("/lockers" + location.search);
  };

  useEffect(() => {
    if (id) {
      refetch();
    } else {
      console.debug('Could not find locker id');
      onClose();
    }
  }, [id]);

  const refetch = () => {
    backend.lockers.getById(parseInt(id!))
      .then((data) => {
        setLocker(data);
      })
      .catch((e) => {
        console.error(e);
        onClose();
      });
  };

  const handleRent = () => {
    if (locker) {
      const path = `/lockers/${locker.id}/${locker.rentStatus?.status !== undefined ? 'return' : 'rent'}`;
      navigate(path, { state: { previousLocation: location.pathname } });
    }
  };

  const handleEdit = () => {
    if (locker) {
      navigate(`/lockers/${locker.id}/edit`, { state: { previousLocation: location.pathname } });
    }
  };

  const handleDelete = () => {
    if (locker) {
      const isDeleted = locker.deleted && new Date(locker.deleted) > new Date(0);
      const path = `/lockers/${locker.id}/${isDeleted ? 'restore' : 'delete'}`;
      navigate(path, { state: { previousLocation: location.pathname } });
    }
  };

  useFormKeyBindings({
    handleClose: onClose,
    handleRent,
    handleEdit,
    handleDelete
  });

  return (
    <>
      <Modal isCentered={true} isOpen={true} onClose={onClose} size='3xl'>
        {locker ? (
          <ItemModal
            headerLine2={locker.lockerNumber}
            item={locker}
            onClose={onClose}
          >
            <VStack spacing={8} align='flex-start'>
              <Stack direction='column' flex={1} align='stretch' spacing={4}>
                <Heading size='md'>Specifications</Heading>
                <HStack align='flex-start' wrap='wrap' gap={10}>
                  <VStack align='flex-start' spacing={0}>
                    <Heading size='sm'>KEY NUMBER</Heading>
                    <Text size='sm'>{locker.keyNumber}</Text>
                  </VStack>
                  {locker.lockerLocation && (
                    <VStack align='flex-start' spacing={0}>
                      <Heading size='sm'>LOCATION</Heading>
                      <Text size='sm'>{locker.lockerLocation}</Text>
                    </VStack>
                  )}
                </HStack>
              </Stack>
              {locker.description && (
                <VStack flex={1} align='flex-start' spacing={2} whiteSpace='pre-line'>
                  <Heading size='md'>Description</Heading>
                  <Text size='sm'>{locker.description}</Text>
                </VStack>
              )}
              {locker.rentStatus?.note && (
                <VStack flex={1} align='flex-start' spacing={2}>
                  <Heading size='md'>Notes</Heading>
                  <Text size='sm' whiteSpace='pre-line'>
                    {locker.rentStatus.note}
                  </Text>
                </VStack>
              )}
            </VStack>
          </ItemModal>
        ) : (
          <ModalContent bg="transparent" shadow="none">
            <Center>
              <Spinner size='lg' />
            </Center>
          </ModalContent>
        )}
      </Modal>
      <Outlet />
    </>
  );
};