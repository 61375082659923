import { ButtonGroup, Heading, IconButton, Stack, Tag, Tooltip } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { InitialTableState, ColumnDef } from "@tanstack/react-table";
import { TanstackTable } from "../../components/TanstackTable";
import { Keycard, keycardToString } from "../../models/keycard";

type UserKeyCardsProps = {
  email: string,
  keycards: Keycard[]
}

export const UserKeyCards = ({email, keycards}: UserKeyCardsProps) => {

  const navigate  = useNavigate();
  const [tableData, setTableData] = useState<Keycard[]>([]);
  const location = useLocation()

  useEffect(() => {
    setTableData(keycards)
  }, [keycards])

  const initialTableState: InitialTableState = {
    sorting: [{ id: 'id', desc: true }],
    columnVisibility: {
      'cardId': false
    }
  }

  const buttonGroup = (id: number) => {
    return(
      <ButtonGroup variant="ghost">
        <Tooltip label="Return">
          <IconButton
            aria-label="return"
            icon={<KeyboardReturnIcon />}
            onClick={(event) => {
              event.stopPropagation();
              navigate(`keycard/${id}/return`, { state: { previousLocation: location.pathname} })
            }}
          />
        </Tooltip>
      </ButtonGroup>
    )
  }

  const statusTags = (status: number | undefined) => {
    if (status != undefined) {
      switch (status) {
        case 0:
          return <Tag colorScheme='red'>Owned</Tag>
        case 1:
          return <Tag colorScheme='orange'>Rented</Tag>
        default:
          throw new Error("Invalid status")
      }
    }else{
      //case rentStatus.AVAILABLE:
      return <Tag colorScheme='green'>Available</Tag>
    }
  }

  const modelTags = (type: string) => {
    const colorSchemes = ["red", "orange", "yellow", "green", "teal", "blue", "cyan", "purple", "pink", "linkedin", "facebook", "messenger", "whatsapp", "twitter", "telegram"]
    if (type) {
      const colorScheme = Math.abs(type.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)) % colorSchemes.length
      return <Tag colorScheme={colorSchemes[colorScheme]}>{type}</Tag>
    }
  }

  const onRowClick = (item: Keycard) => {
    navigate(`keycard/${item.id}`, { state: { previousLocation: location.pathname } })
  }

  const columns: ColumnDef<Keycard>[] = [
    {
      id: 'type',
      accessorFn: row => row.type,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'Type',
      cell: (info) => modelTags(info.getValue() as string),
    },
    {
      id: 'status',
      accessorFn: row => row.rentStatus?.status ?? 3,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'Status',
      cell: info => statusTags(info.row.original.rentStatus?.status),
    },
    {
      id: 'actions',
      header: 'Actions',
      size: 10,
      enableHiding: false,
      maxSize: 10,
      cell: info => buttonGroup(info.row.original.id),
      meta: {
        isNumeric: true
      }
    },
  ]

  return (
    <Stack spacing='4'>
      <Heading marginLeft='4' size='lg'>Keycards</Heading>
      <TanstackTable
        columns={columns}
        data={tableData}
        initialTableState={initialTableState}
        onRowClick={(item: Keycard) => onRowClick(item)}
        onSortingChange={(newSorting) => console.log(newSorting)}
        isLoading={false}       
        storageKey='userKeycardTableTableVisibility'/>
    </Stack>
  )
}