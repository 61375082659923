import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetch } from '../../contexts/FetchContext';
import { useForm } from '@tanstack/react-form';
import { FormModal } from '../../components/Helpers/FormModal';
import {
  Button,
  Checkbox,
  Heading,
  useToast,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Box,
  Text,
  Flex,
  WrapItem,
  Wrap,
  Select
} from '@chakra-ui/react';
import { IdentityRole } from '../../models/roles';
import { useFormKeyBindings } from '../../components/Helpers/useFormKeyBindings';

export const EditRolePermissions = () => {
  const [availablePermissions, setAvailablePermissions] = useState<any[]>([]);
  const [rolePermissions, setRolePermissions] = useState<number[]>([]);
  const [company, setCompany] = useState<string>('');
  const [role, setRole] = useState<IdentityRole | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const { backend } = useFetch();
  const navigate = useNavigate();
  const toast = useToast();

  const onClose = () => navigate(-1);

  const fetchData = useCallback(() => {
    if (id) {
      // setLoading(true);

      Promise.all([
        backend.permission.getPermissionsForRole(id),
        backend.permission.getAllPermissions(),
        backend.role.getAllRoles()
      ])
        .then(([fetchedRolePermissions, allPermissions, allRoles]) => {
          const fetchedRolePermissionIDs = fetchedRolePermissions.map((p: any) => p.id);
          setRolePermissions(fetchedRolePermissionIDs);

          setAvailablePermissions(allPermissions);

          const currentRole = allRoles.find(r => r.id === id);
          setRole(currentRole || null);
          setCompany(currentRole?.company || '');
          // setLoading(false);
        })
        .catch((error) => {
          // setLoading(false);
          toast({
            title: 'Error fetching data',
            description: 'Unable to load role permissions or available permissions. Please try again.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
    }
  }, [id, backend.permission, backend.role, toast]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    form.setFieldValue('rolePermissions', rolePermissions);
    form.setFieldValue('company', company);
    form.setFieldValue('roleName', role?.name || '');
  }, [rolePermissions, role, company]);

  const form = useForm({
    defaultValues: {
      roleName: '',
      company: '',
      rolePermissions: [] as number[],
    },
    onSubmit: async (values) => {
      if (!id) {
        return null;
      }

      try {
        if (values.roleName !== role?.name) {
          await backend.permission.updateRoleName(id, values.roleName);
        }

        if (values.company !== role?.company) {
          await backend.permission.assignCompanyFromRole(id, values.company);
        }

        const permissionsToAdd = values.rolePermissions.filter(p => !rolePermissions.includes(p));
        const permissionsToRemove = rolePermissions.filter(p => !values.rolePermissions.includes(p));

        for (const permissionId of permissionsToAdd) {
          await backend.permission.assignPermissionToRole(permissionId, id);
        }
        for (const permissionId of permissionsToRemove) {
          await backend.permission.removePermissionFromRole(permissionId, id);
        }

        toast({
          title: 'Role updated',
          description: 'Role have been successfully updated.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        navigate(-1);
      } catch (error) {
        console.error('Error updating role:', error);
        toast({
          title: 'Error updating role',
          description: 'There was an error updating the role. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });

  useFormKeyBindings({
    handleSubmit: form.handleSubmit,
    handleClose: onClose,
    canSubmit: form.state.canSubmit,
    isSubmitting: form.state.isSubmitting
  });

  const groupPermissions = (permissions: any[]) => {
    const groups: { [key: string]: any[] } = {};
    permissions.forEach(permission => {
      const match = permission.name.match(/[A-Z][a-z]+$/);
      const groupName = match ? match[0] : 'Uncategorized';
      if (!groups[groupName]) {
        groups[groupName] = [];
      }
      groups[groupName].push(permission);
    });
    return groups;
  };

  const FormFields = [
    <form.Field key="roleName" name="roleName">
      {(field) => (
        <FormControl>
          <FormLabel>Role Name</FormLabel>
          <Input
            autoFocus
            value={field.state.value}
            onChange={(e) => {
              field.handleChange(e.target.value);
            }}
          />
        </FormControl>
      )}
    </form.Field>,
    <form.Field key="company" name="company">
      {(field) => (
        <FormControl isInvalid={field.state.meta.errors.length > 0}>
          <FormLabel>Company</FormLabel>
          <Select
            variant='filled'
            name={field.name}
            value={field.state.value}
            onChange={(e) => {
              field.handleChange(e.target.value);
            }}
          >
            <option value="None">Select Company</option>
            <option value="All">All</option>
            <option value="Knowit Objectnet">Knowit Objectnet</option>
            <option value="Knowit Experience Oslo">Knowit Experience Oslo</option>
            <option value="Knowit Solutions Norway">Knowit Solutions Norway</option>
            <option value="Knowit Impact">Knowit Impact</option>
            <option value="Knowit Dataess">Knowit Dataess</option>
            <option value="Knowit Decision">Knowit Decision</option>
          </Select>
        </FormControl>
      )}
    </form.Field>,
    <form.Field key="rolePermissions" name="rolePermissions">
      {(field) => (
        <FormControl>
          <FormLabel>Role Permissions</FormLabel>
          <Wrap spacing={4} justify="flex-start">
            {Object.entries(groupPermissions(availablePermissions)).map(([groupName, permissions], index) => (
              <WrapItem key={`${groupName}-${index}`} flexGrow={1} minW="250px" maxW="calc(25% - 16px)">
                <Box w="100%" borderWidth="1px" borderRadius="md" p={3} bg="gray.800">
                  <Flex justifyContent="space-between" alignItems="center" mb={2}>
                    <Text fontWeight="bold">{groupName}</Text>
                    <Button
                      size="sm"
                      variant="outline"
                      onClick={() => {
                        const allGroupIds = permissions.map(p => p.id);
                        const isAllChecked = allGroupIds.every(id => field.state.value.includes(id));
                        const newPermissions = isAllChecked
                          ? field.state.value.filter((p: number) => !allGroupIds.includes(p))
                          : Array.from(new Set([...field.state.value, ...allGroupIds]));
                        field.handleChange(newPermissions);
                      }}
                    >
                      {permissions.every(p => field.state.value.includes(p.id)) ? 'Uncheck All' : 'Check All'}
                    </Button>
                  </Flex>
                  <VStack align="start" spacing={2}>
                    {permissions.map((permission) => {
                      const permissionId = permission.id;
                      return (
                        <Checkbox
                          key={permissionId}
                          id={`permission-${permissionId}`}
                          isChecked={field.state.value.includes(permissionId)}
                          onChange={(e) => {
                            const newPermissions = e.target.checked
                              ? [...field.state.value, permissionId]
                              : field.state.value.filter((p: number) => p !== permissionId);
                            field.handleChange(newPermissions);
                          }}
                        >
                          {permission.name}
                        </Checkbox>
                      );
                    })}
                  </VStack>
                </Box>
              </WrapItem>
            ))}
          </Wrap>
        </FormControl>
      )}
    </form.Field>
  ];

  const SubmitButton = () => (
    <Button colorScheme="blue" isLoading={form.state.isSubmitting} type="submit" disabled={!form.state.canSubmit}>
      Update Role
    </Button>
  );

  const Header = () => (
    <Heading size="xl" mt={5}>Edit Role: {role?.name}</Heading>
  );


  return (
    <FormModal
      isLoading={isLoading}
      headerElements={[<Header key="header" />]}
      form={form}
      formFields={FormFields}
      SubmitButton={<SubmitButton />}
      onClose={onClose}
    />
  );
};