import { Stack, Card, CardBody, VStack, HStack, Heading, Text, useColorModeValue } from "@chakra-ui/react";
import { InitialTableState } from "@tanstack/react-table";
import { useState, useContext, useEffect, useMemo } from "react";
import { useLocation, Outlet } from "react-router-dom";
import { AppContext } from "../contexts/AppContext";
import { useFetch } from "../contexts/FetchContext";
import { ItemCollection } from "../models/itemCollection";
import { UserComputer } from "./User/UserComputer";
import { UserKeyCards } from "./User/UserKeyCards";
import { UserLicenses } from "./User/UserLicenses";
import { UserContext } from "../auth/Auth";
import { License } from "../models/newLicenses";
import { UserLockers } from "./User/UserLockers";
import { EquipitUser } from "../models/user";

export const Equipment = () => {
  const [items, setItems] = useState<ItemCollection>();
  const [licenses, setLicenses] = useState<License[]>();
  const [userInfo, setUserInfo] = useState<EquipitUser>();
  const bodyBg = useColorModeValue('#f1f1f1', "gray.900");

  const { email } = useContext(UserContext)!;
  const app = useContext(AppContext);
  const { backend } = useFetch();
  app.setTitle("Equipment")
  const location = useLocation()

  useEffect(() => {
    refetch()
  }, [location])

  const refetch = () => {
    if (email) {
      // Fetch user info
      backend.users.getAll(email).then((users: EquipitUser[]) => {
        if (users.length > 0) {
          setUserInfo(users[0]);
        }
      }).catch((err) => {
        console.log(err)
      })

      backend.items.getByEmail(email).then((data) => {
        if (data) {
          setItems(data);
        }
      }).catch((err) => {
        console.log(err)
      })
      
      backend.licenses.getAll({ UserEmails: [email] }).then((licenses: License[]) => {
        if (licenses.length > 0) {
          setLicenses(licenses);
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  useEffect(() => {
    if (location.pathname === '/user') refetch()
  }, [location])

  const isLoading = useMemo(() => {
    return items ? false : true
  }, [items])

  const initialTableState: InitialTableState = {
    sorting: [{ id: 'id', desc: true }],
    columnVisibility: {
      'cardId': false
    }
  }

  return (
    <Stack spacing='6'>
      {email && userInfo && (
        <Card bg={bodyBg}>
          <CardBody>
            <VStack spacing={12} align='flex-start'>
              <Stack direction='column' flex={1} align='stretch' spacing={8}>
                <Heading size='lg'>{userInfo.displayName}</Heading>
                
                <HStack align='flex-start' wrap='wrap' gap={16}>
                  <VStack align='flex-start' spacing={2}>
                    <Heading size='sm'>EMAIL</Heading>
                    <Text size='sm'>{userInfo.email}</Text>
                  </VStack>

                  {userInfo.company && (
                    <VStack align='flex-start' spacing={2}>
                      <Heading size='sm'>COMPANY</Heading>
                      <Text size='sm'>{userInfo.company}</Text>
                    </VStack>
                  )}

                  {userInfo.department && (
                    <VStack align='flex-start' spacing={2}>
                      <Heading size='sm'>DEPARTMENT</Heading>
                      <Text size='sm'>{userInfo.department}</Text>
                    </VStack>
                  )}
                </HStack>

                <HStack align='flex-start' wrap='wrap' gap={16}>
                  {userInfo.location && (
                    <VStack align='flex-start' spacing={2}>
                      <Heading size='sm'>LOCATION</Heading>
                      <Text size='sm'>{userInfo.location}</Text>
                    </VStack>
                  )}
                  
                  {userInfo.telephoneNumber && (
                    <VStack align='flex-start' spacing={2}>
                      <Heading size='sm'>PHONE</Heading>
                      <Text size='sm'>{userInfo.telephoneNumber}</Text>
                    </VStack>
                  )}
                </HStack>
              </Stack>
            </VStack>
          </CardBody>
        </Card>
      )}

      {email && (
        <Stack spacing={6}>
          {(items?.computers?.length || 0) > 0 && (
            <UserComputer email={email} computers={items!.computers!} />
          )}
          {(items?.keyCards?.length || 0) > 0 && (
            <UserKeyCards email={email} keycards={items!.keyCards!} />
          )}
          {(items?.lockers?.length || 0) > 0 && (
            <UserLockers email={email} lockers={items!.lockers!} />
          )}
          {(licenses?.length || 0) > 0 && (
            <UserLicenses email={email} licenses={licenses!} />
          )}
        </Stack>
      )}
      <Outlet />
    </Stack>
  )
}