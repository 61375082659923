import { LicenseButton } from "../LicenseButton"


export const sideBarLinks = {
  admin: {
    policy: [
      {
        path: '/users',
        title: 'Users',
        alloweduser: ['Admin'],
      },
      {
       path: '/roles',
       title: 'Roles',
       alloweduser: ['Admin'],
     },
    ],
    equipment: [
      {
        path: '/computers',
        title: 'Computers',
        alloweduser: ['Admin', 'Moderator'],
      },
      {
        path: '/keycards',
        title: 'Keycards',
        alloweduser: ['Admin', 'Moderator', 'Receptionist'],
      },
      { 
        path: '/lockers',
        title: 'Lockers',
        alloweduser: ['Admin', 'Moderator', 'Receptionist'],
      }
      ],
    other: [
      {
        path: '/manufacturers',
        title: 'Manufacturers and Models',
        alloweduser: ['Admin', 'Moderator'],
      },
    ],
    licenses: [
      {
        path: '/licenses',
        title: 'Licenses',
        alloweduser: ['Admin', 'Moderator'],
        customComponent: <LicenseButton />,
      },
      {
        path: '/manage-licenses',
        title: 'Manage Licenses',
        alloweduser: ['Admin', 'Moderator'],
      }
    ]
  }
}
