import { Button, FormLabel, Heading, Input, Textarea } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from '@tanstack/react-form';
import { FormControl } from '@chakra-ui/react';
import { useFetch } from "../../contexts/FetchContext";
import { FormModal } from "../../components/Helpers/FormModal";
import { Locker } from "../../models/lockers";
import { useFormKeyBindings } from "../../components/Helpers/useFormKeyBindings";

export const EditLocker = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [locker, setLocker] = useState<Locker | null>(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const { backend } = useFetch();

  const refetch = () => {
    if (id) {
      setLoading(false);
      backend.lockers.getById(parseInt(id))
        .then((data) => {
          setLocker(data);
          setLoading(true);
        })
        .catch((error) => {
          console.error('Error fetching locker:', error);
          onClose();
        });
    }
  };

  useEffect(() => {
    refetch();
  }, []);

  const onClose = () => {
    navigate(-1);
  };

  const form = useForm({
    defaultValues: {
      lockerNumber: locker?.lockerNumber || '',
      keyNumber: locker?.keyNumber || '',
      lockerLocation: locker?.lockerLocation || '',
      description: locker?.description || '',
    },
    onSubmit: async (values) => {
      if (await form.validateAllFields("submit") && locker) {
        const updatedLocker: Locker = {
          ...locker,
          lockerNumber: values.lockerNumber,
          keyNumber: values.keyNumber,
          lockerLocation: values.lockerLocation || undefined,
          description: values.description || undefined,
        };
        try {
          await backend.lockers.update(updatedLocker);
          onClose();
        } catch (error) {
          console.error(error);
        }
      }
    }
  });

  useFormKeyBindings({
    handleSubmit: form.handleSubmit,
    handleClose: onClose,
    canSubmit: form.state.canSubmit,
    isSubmitting: form.state.isSubmitting
  });


  const FormFields = [
    (
      <form.Field
        name="lockerNumber"
        onChange={(value) => {
          return !value || value.length === 0
            ? 'Locker Number is required'
            : undefined;
        }}
        children={field => (
          <FormControl isInvalid={field.state.meta.errors.length > 0}>
            <FormLabel>Locker Number</FormLabel>
            <Input
              autoFocus
              variant='filled'
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              placeholder="Locker Number"
              required
            />
          </FormControl>
        )}
      />
    ),
    (
      <form.Field
        name="keyNumber"
        onChange={(value) => {
          return !value || value.length === 0
            ? 'Key Number is required'
            : undefined;
        }}
        children={field => (
          <FormControl isInvalid={field.state.meta.errors.length > 0}>
            <FormLabel>Key Number</FormLabel>
            <Input
              variant='filled'
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              placeholder="Key Number"
              required
            />
          </FormControl>
        )}
      />
    ),
    (
      <form.Field
        name="lockerLocation"
        children={field => (
          <FormControl>
            <FormLabel>Locker Location</FormLabel>
            <Input
              variant='filled'
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              placeholder="Locker Location (Optional)"
            />
          </FormControl>
        )}
      />
    ),
    (
      <form.Field
        name="description"
        children={field => (
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Textarea
              name="description"
              value={field.state.value}
              onChange={(e) => field.handleChange(e.target.value)}
              placeholder="Description (Optional)"
            />
          </FormControl>
        )}
      />
    )
  ];

  const SubmitButton = () => (
    <Button
      colorScheme='blue'
      isLoading={form.state.isSubmitting}
      type="submit"
      disabled={!form.state.canSubmit}
    >
      Update
    </Button>
  );

  const Header = () => (
    <Heading size='xl' mt={5}>Edit Locker</Heading>
  );

  return (
    <FormModal
      isLoading={isLoading} 
      headerElements={[<Header />]}
      form={form}
      formFields={FormFields}
      SubmitButton={<SubmitButton />}
      onClose={onClose}
    />
  );
};