import { Box, Drawer, DrawerContent, DrawerOverlay, Grid, GridItem, IconButton, ModalCloseButton, useMediaQuery } from "@chakra-ui/react"
import { Outlet, useLocation } from "react-router-dom"
import Header from "./Header"
import Sidebar from "./Sidebar/Sidebar"
import { useEffect, useState, useCallback } from "react"
import { HamburgerIcon } from "@chakra-ui/icons"

export const Layout = () => {
  const location = useLocation()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false)

  const handleKeyboardShortcuts = useCallback((event: KeyboardEvent) => {
    if (event.ctrlKey && event.key.toLowerCase() === 'u') {
      event.preventDefault();
      const userSearchInput = document.querySelector('[data-user-search="true"]') as HTMLInputElement;
      if (userSearchInput) {
        userSearchInput.focus();
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardShortcuts);
    return () => {
      window.removeEventListener('keydown', handleKeyboardShortcuts);
    };
  }, [handleKeyboardShortcuts]);

  useEffect(() => {
    console.debug(location)
    setIsOpen(false)
  }, [location])

  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)')

  if (isLargerThan1280) {
    return(
      <Grid
        h='100%'
        w='100%'
        templateAreas={`
          "nav header"
          "nav main"`}
        gridTemplateRows={'175px 1fr'}
        gridTemplateColumns={`${isSidebarCollapsed ? '80px' : '400px'} minmax(0, 1fr)`}
        transition="all 0.3s ease"
      >
        <GridItem area={'nav'}>
          <Sidebar isCollapsed={isSidebarCollapsed} onToggleCollapse={() => setIsSidebarCollapsed(!isSidebarCollapsed)}/>
        </GridItem>
        <GridItem area={'header'}>
          <Box w='100%' maxW='100%'>
            <Header/>
          </Box>
        </GridItem>
        <GridItem area={'main'} p={4}>
          <Box w='100%' maxW='100%'>
            <Outlet/>
          </Box>
        </GridItem>
      </Grid>   
    )
  } else {
    return(
      <>
        <Grid
          h='100%'
          w='100%'
          templateAreas={`
            "nav header"
            "main main"`}
          gridTemplateRows={'175px 1fr'}
          gridTemplateColumns={'72px minmax(0, 1fr)'}
        >
          <GridItem area={'nav'}>
            <IconButton marginTop={20} marginLeft={8} icon={<HamburgerIcon/>} aria-label="navigation" onClick={() => setIsOpen(true)}/>
          </GridItem>
          <GridItem area={'header'}>
            <Box w='100%' maxW='100%'>
              <Header/>
            </Box>
          </GridItem>
          <GridItem area={'main'} p={4}>
            <Box w='100%' maxW='100%'>
              <Outlet/>
            </Box>
          </GridItem>
        </Grid>
        <Drawer isOpen={isOpen} onClose={() => setIsOpen(false)} size={'sm'} placement={'left'}>
          <DrawerOverlay />
          <DrawerContent>
            <ModalCloseButton />
            <Sidebar isCollapsed={false} onToggleCollapse={() => {}}/>
          </DrawerContent>
        </Drawer>
      </>
    )
  }
}