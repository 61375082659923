import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { Flex, Table as ChakraTable, Thead, Tbody, Tr, Th, Td, Spinner, useColorModeValue, chakra, Box, HStack } from '@chakra-ui/react';
import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  InitialTableState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import { MultiSelect } from './MultiSelect';

export interface TableProps<T> {
  data: T[] | undefined;
  columns: ColumnDef<T>[];
  isLoading: boolean;
  initialTableState?: InitialTableState;
  onRowClick?: (item: T) => void;
  onSortingChange: (sorting: SortingState) => void;
  storageKey: string;
}

export const TanstackTable = <T,>({ columns, data = [], initialTableState, isLoading, onRowClick, onSortingChange, storageKey }: TableProps<T>) => {
  const [sorting, setSorting] = useState<SortingState>(initialTableState?.sorting ?? []);
  const [columnVisibility, setColumnVisibility] = useState(initialTableState?.columnVisibility ?? {});
  const [expanded, setExpanded] = React.useState<ExpandedState>({})

  const table = useReactTable({
    columns: columns,
    data,
    manualSorting: true,
    state: {
      expanded,
      sorting,
      columnVisibility
    },
    onExpandedChange: setExpanded,
    getSubRows: (row: any) => row?.subRows || [],
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    initialState: initialTableState,
  });


  useEffect(() => {
    setColumnVisibility(columnVisibility)
  }, [columnVisibility])

  const rowClick = (item: T) => {
    if (onRowClick) {
      onRowClick(item);
    }
  };

  const toogleColumnOptions = columns.filter(column => column.enableHiding).map((column) => ({
    key: column.id as string,
    label: column.header as string
  }))

  const rowColor = useColorModeValue('#dee2e6', 'gray.800');
  const bodyBg = useColorModeValue('#f1f1f1', "gray.900")
  const dividerBg = useColorModeValue('#adb5bd', "gray.700")

  return (
    <Box
      bg={bodyBg}
      shadow='md'
      p={4}
      borderRadius={6}
      w='100%'
    >
      <HStack
        w="100%"
        spacing={4}
        marginBottom={4}
        align="flex-end"
      >
        <MultiSelect
          mode='reduce'
          useTags={false}
          label='Columns'
          options={toogleColumnOptions}
          storageKey = {storageKey}
          onChange={(selected) => {
            const temp: {[k: string]: boolean} = {}
            toogleColumnOptions.map((option) => {
              return temp[option.key] = false
            })
            selected.forEach((option) => {
              temp[option.key] = true;
            })
            console.debug(temp)
            setColumnVisibility(temp)
          }}
        />
      </HStack>
      <Flex ml='-4' mr='-4'>
        <Flex w='100%' overflowX='scroll'>
          <ChakraTable variant='simple' size="sm" w='100%'>
            <Thead>
              {table.getHeaderGroups().map((group) => (
                <Tr key={group.id} >
                  {group.headers.map((header) => {
                    const canSort = header.column.getCanSort();
                    const meta: any = header.column.columnDef.meta;
                    return (
                      <Th
                      borderColor={dividerBg}
                        cursor={canSort? 'pointer':""}
                        key={header.id}
                        /* colSpan={header.colSpan} */
                        onClick={ () => {
                          if (!canSort) return;
                          header.column.toggleSorting()
                          if (header.column.getIsSorted() === "desc") {
                            sorting.pop()
                          } else {
                            sorting[0] = {
                              id: header.column.id,
                              desc: header.column.getIsSorted() == "asc"
                            }
                          }
                          setSorting(sorting)
                          onSortingChange(sorting)
                        }}
                        userSelect="none"
                        isNumeric={meta?.isNumeric}
                        /* style={{width: header.getSize()}} */
                      >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          <chakra.span pl="4">
                            {header.column.getIsSorted() ? (
                              header.column.getIsSorted() === "desc" ? (
                                <TriangleDownIcon aria-label="sorted descending" />
                              ) : (
                                <TriangleUpIcon aria-label="sorted ascending" />
                              )
                            ) : null}
                          </chakra.span>
                      </Th>
                    );
                  })}
                  {/* <Th>
                    <Icon as={ChevronUpIcon} display="inline" aria-label="sorted descending" />
                  </Th> */}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {!isLoading &&
                table.getRowModel().rows.map((row) => (
                  <Tr cursor={onRowClick?"pointer":""} _hover={{bg: rowColor}} key={row.id} onClick={() => rowClick(row.original)}>
                    {row.getVisibleCells().map((cell) => {
                      const meta: any = cell.column.columnDef.meta;
                      return(
                        <Td key={cell.id} isNumeric={meta?.isNumeric} borderColor={dividerBg}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      )
                      })}
                  </Tr>
                ))}
            </Tbody>
          </ChakraTable>
        </Flex>
      </Flex>
      {isLoading ? (
        <Flex w="full" alignItems="center" justifyContent="center" p="20">
          <Spinner />
        </Flex>
      ) : null}
    </Box>
  );
};
