import { Button, FormLabel, Heading, Input, InputGroup, InputLeftAddon } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation, } from "react-router-dom";
import { Computer } from "../../models/computer";
import { Manufacturer, Model } from "../../models/manufacturer";
import { Select } from '@chakra-ui/react';
import { useForm } from '@tanstack/react-form'
import { ScanProps } from "../../components/Helpers/ImageScanner";
import { Textarea, FormControl } from '@chakra-ui/react'
import { useFetch } from "../../contexts/FetchContext";
import { FormModal } from "../../components/Helpers/FormModal";
import { useFormKeyBindings } from "../../components/Helpers/useFormKeyBindings";

export const EditComputer = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [imageSpecs, setImageSpecs] = useState<ScanProps>();
  const [computer, setComputer] = useState<Computer>();
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>();
  const [manufacturer, setManufacturer] = useState<Manufacturer>();

  const { id } = useParams();
  const navigate = useNavigate()
  const location = useLocation();
  const { backend } = useFetch();

  const refetch = () => {
    if (id) {
      setLoading(true)
      backend.computers.getById(parseInt(id))
        .then((data) => {
          setComputer(data);
          setManufacturer(data.manufacturer);
        }).catch((err) => {
          console.log(err)
          onClose()
        })
      backend.manufacturers.get()
        .then((data) => {
          setManufacturers(data);
        })
    }
  }

  const getComputerId = (id: number | undefined) => {
    if (id) {
      let value = id.toString()
      while (value.length < 3) value = "0" + value
      return 'PC' + value
    }
  }

  useEffect(() => {
    refetch()
  }, []);


  const onClose = () => {
    navigate(-1);
  };

  const form = useForm({
    defaultValues: {
      serialNumber: computer?.serialNumber || '',
      manufacturer: computer?.manufacturer.id.toString() || '',
      model: computer?.model.id.toString() || '',
      hasEDM: computer?.hasEDM || false,
      computerID: computer?.computerId || null,
      description: computer?.description || '',
      memory: computer?.memory.toString() || '',
      year: computer?.year.toString() || '',
      display: computer?.display.toString() || '',
      disk: computer?.disk.toString() || '',
      company: computer?.company?.toString() || '',

    },
    onSubmit: async (values) => {
      if (await form.validateAllFields("submit") && computer) {
        const updatedComputer: Computer = {
          serialNumber: values.serialNumber!,
          manufacturer: manufacturers!.find(manufacturer => manufacturer.id.toString() === values.manufacturer) as Manufacturer,
          model: (manufacturers!.find(manufacturer => manufacturer.id.toString() === values.manufacturer) as Manufacturer).models.find(model => model.id.toString() === values.model) as Model,
          description: values.description,
          memory: parseInt(values.memory!),
          year: parseInt(values.year!),
          display: parseInt(values.display!),
          disk: parseInt(values.disk!),
          rentStatus: computer.rentStatus,
          id: computer.id,
          computerId: (values.computerID!),
          hasEDM: values.hasEDM,
          deleted: computer.deleted,
          rentHistory: computer.rentHistory,
          company: (values.company),
        }
        backend.computers.update(updatedComputer)
          .then((response) => {
            onClose();
          }).catch((error) => {
            console.log(error)
          });
      }
    }
  })

  useFormKeyBindings({
    handleSubmit: form.handleSubmit,
    handleClose: onClose,
    canSubmit: form.state.canSubmit,
    isSubmitting: form.state.isSubmitting
  });


  useEffect(() => {
    /* update computer based on imageSpecs */
    if (imageSpecs) {
      const manufacturerId = manufacturers!.find(manufacturer => manufacturer.name.includes(imageSpecs.manufacturer!))?.id.toString() || undefined;
      const modelId = (manufacturers!.find(manufacturer => manufacturer.name.includes(imageSpecs.manufacturer!)) as Manufacturer).models.find(model => model.name.toLowerCase().includes(imageSpecs.model!))?.id.toString() || undefined;

      if (imageSpecs.serial) {
        form.setFieldValue('serialNumber', imageSpecs.serial);
      }
      if (manufacturerId) {
        form.setFieldValue('manufacturer', manufacturerId);
      }
      if (modelId) {
        form.setFieldValue('model', modelId);
      }
      if (imageSpecs.hasEDM) {
        form.setFieldValue('hasEDM', true);
      }
      if (imageSpecs.computerID) {
        form.setFieldValue('computerID', imageSpecs.computerID);
      }
      if (imageSpecs.data) {
        form.setFieldValue('description', imageSpecs.data);
      }
      if (imageSpecs.specs?.ram) {
        form.setFieldValue('memory', imageSpecs.specs?.ram);
      }
      if (imageSpecs.specs?.storage) {
        form.setFieldValue('disk', imageSpecs.specs?.storage);
      }
      if (imageSpecs.specs?.display) {
        form.setFieldValue('display', imageSpecs.specs?.display);
      }
    }
  }, [imageSpecs]);

  const Template = (type: string) => {
    setManufacturer(manufacturers!.find(manufacturer => manufacturer.name === type) as Manufacturer);
    form.setFieldValue('disk', '1024')
    form.setFieldValue('memory', '32')
    form.setFieldValue('display', '13')
    form.setFieldValue('year', '2022')
    form.setFieldValue('manufacturer', manufacturers!.find(manufacturer => manufacturer.name === type)?.id.toString() || '');
    form.setFieldValue('model', (manufacturers!.find(manufacturer => manufacturer.name === type) as Manufacturer).models[0].id.toString());
    form.setFieldValue('hasEDM', false);
    form.setFieldValue('company', "Knowit Objectnet");
  };

  const FormFields = [
    (
      <form.Field
        name="serialNumber"
        onChange={(value) => {
          return !value || value.length === 0
            ? 'Serial number is required'
            : undefined
        }}
        children={field => {
          return (
            <FormControl isRequired isInvalid={field.state.meta.errors.length > 0}>
              <FormLabel>Serialnumber</FormLabel>
              <Input autoFocus variant='filled' required name={field.name} value={field.state.value} onBlur={field.handleBlur} onChange={(e) => field.handleChange(e.target.value)} placeholder="XXXXXXXXX" />
            </FormControl>
          )
        }}
      />
    ), (
      <form.Field
        name="manufacturer"
        onChange={(value) => {
          form.setFieldValue('model', '')
          return !value || value.length === 0
            ? 'Manufacturer is required'
            : undefined
        }}
        children={field => {
          return (
            <FormControl isRequired isInvalid={field.state.meta.errors.length > 0}>
              <FormLabel>Manufacturer</FormLabel>
              <Select variant='filled' required name={field.name} placeholder="Select manufacturer" value={field.state.value} onChange={(e) => field.handleChange(e.target.value)}>
                {manufacturers!.map(manufacturer => (
                  <option key={manufacturer.id} value={manufacturer.id}>
                    {manufacturer.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          )
        }}
      />
    ), (
      <form.Field
        name="model"
        onChange={(value) => {
          return !value || value.length === 0
            ? 'Model is required'
            : undefined
        }}
        children={field => {
          return (
            <FormControl isRequired isInvalid={field.state.meta.errors.length > 0}>
              <FormLabel>Model</FormLabel>
              <Select variant='filled' required name={field.name} placeholder={"Select Model"} isDisabled={!form.getFieldValue("manufacturer")} value={field.state.value} onChange={(e) => field.handleChange(e.target.value)}>
                {manufacturers?.find(manufacturer => manufacturer.id.toString() === form.getFieldValue("manufacturer"))?.models.map(model => (
                  <option key={model.id} value={model.id}>
                    {model.name}
                  </option>
                )) || []}
              </Select>
            </FormControl>
          )
        }}
      />
    ), (
      <form.Field
        name="hasEDM"
        children={field => {
          return (
            <FormControl>
              <FormLabel>Has EDM</FormLabel>
              <Select variant='filled' name={field.name} value={field.state.value == true ? "true" : "false"} onChange={(e) => field.handleChange(e.target.value == "true")}>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Select>
            </FormControl>
          )
        }}
      />
    ), (
      <form.Field
        name="computerID"
        children={field => {
          return (
            <FormControl isInvalid={field.state.meta.errors.length > 0}>
              <FormLabel>Computer ID</FormLabel>
              <InputGroup>
                <InputLeftAddon children="PC" />
                <Input
                  name="computerID"
                  placeholder="Computer ID"
                  required
                  value={field.state.value || ''}
                  type="number"
                  onChange={(e) => field.handleChange(e.target.valueAsNumber)}
                />
              </InputGroup>
            </FormControl>
          )
        }}
      />
    ), (
      <form.Field
        name="company"
        children={field => {
          return (
            <FormControl isInvalid={field.state.meta.errors.length > 0}>
              <FormLabel>Company</FormLabel>
              <Select variant='filled' name={field.name} value={field.state.value} onChange={(e) => field.handleChange(e.target.value)}>
                <option value=''>Select Company</option>
                <option value="Knowit Objectnet">Knowit Objectnet</option>
                <option value="Knowit Experience Oslo">Knowit Experience Oslo</option>
                <option value="Knowit Solutions Norway">Knowit Solutions Norway</option>
                <option value="Knowit Impact">Knowit Impact</option>
                <option value="Knowit Dataess">Knowit Dataess</option>
                <option value="Knowit Decision">Knowit Decision</option>
              </Select>
            </FormControl>
          )
        }}
      />
    ), (
      <form.Field
        name="description"
        children={field => {
          return (
            <FormControl isInvalid={field.state.meta.errors.length > 0}>
              <FormLabel>Desciption</FormLabel>
              <Textarea
                name="description"
                value={field.state.value}
                onChange={(e) => field.handleChange(e.target.value)}
              >
              </Textarea>
            </FormControl>
          )
        }}
      />
    ), (
      <form.Field
        name="disk"
        onChange={
          (value) => {
            return !value || value.length === 0
              ? 'Disk size is required'
              : undefined
          }
        }
        children={field => {
          return (
            <FormControl isInvalid={field.state.meta.errors.length > 0}>
              <FormLabel>Specs</FormLabel>
              <Select variant='filled' required name={field.name} value={field.state.value} onChange={(e) => field.handleChange(e.target.value)}>
                <option>Select disk size</option>
                <option value="128">128GB</option>
                <option value="256">256GB</option>
                <option value="512">512GB</option>
                <option value="1024">1024GB</option>
                <option value="2048">2048GB</option>
              </Select>
            </FormControl>
          )
        }}
      />
    ), (
      <form.Field
        name="memory"
        onChange={
          (value) => {
            return !value || value.length === 0
              ? 'Memory size is required'
              : undefined
          }
        }
        children={field => {
          return (
            <FormControl isInvalid={field.state.meta.errors.length > 0}>
              <Select variant='filled' required name={field.name} value={field.state.value} onChange={(e) => field.handleChange(e.target.value)}>
                <option>Select memory size</option>
                <option value="4">4GB</option>
                <option value="8">8GB</option>
                <option value="16">16GB</option>
                <option value="32">32GB</option>
                <option value="36">36GB</option>
                <option value="48">48GB</option>
                <option value="64">64GB</option>
              </Select>
            </FormControl>
          )
        }}
      />
    ), (
      <form.Field
        name="display"
        onChange={
          (value) => {
            return !value || value.length === 0
              ? 'Display size is required'
              : undefined
          }
        }
        children={field => {
          return (
            <FormControl isInvalid={field.state.meta.errors.length > 0}>
              <Select variant='filled' required name={field.name} value={field.state.value} onChange={(e) => field.handleChange(e.target.value)}>
                <option>Select display size</option>
                <option value="13">13"</option>
                <option value="14">14"</option>
                <option value="15">15"</option>
                <option value="16">16"</option>
                <option value="17">17"</option>
              </Select>
            </FormControl>
          )
        }}
      />
    ), (
      <form.Field
        name="year"
        onChange={
          (value) => {
            return !value || value.length === 0
              ? 'Year is required'
              : undefined
          }
        }
        children={field => {
          return (
            <FormControl isInvalid={field.state.meta.errors.length > 0}>
              <Select variant='filled' required name={field.name} value={field.state.value} onChange={(e) => field.handleChange(e.target.value)}>
                <option value="">Select year</option>
                {years.map((year, index) => (
                  <option key={index} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
            </FormControl>
          )
        }}
      />
    )
  ]

  const SubmitButton = () => {
    return (
      <Button colorScheme='blue' isLoading={form.state.isSubmitting} type="submit" disabled={!form.state.canSubmit}>
        Update
      </Button>
    )
  }

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 30 }, (_, i) => currentYear - i);

  return (
    <FormModal
      isLoading={manufacturers && computer ? true : false}
      headerElements={[<Heading size='xl' mt={5}>Update {getComputerId(computer?.computerId)}</Heading>]}
      form={form}
      formFields={FormFields}
      SubmitButton={<SubmitButton />}
      onClose={onClose}
    />
  )
}
