import { Button, Center, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, VStack, useColorModeValue } from "@chakra-ui/react";
import type { FormApi } from '@tanstack/react-form'

type FormModalProps = {
  isLoading: boolean;
  headerElements: JSX.Element[];
  form: FormApi<any>
  formFields: JSX.Element[];
  SubmitButton: JSX.Element;
  onClose: () => void;
}

export const FormModal = (props: FormModalProps) => {

  const {isLoading, headerElements, form, formFields, SubmitButton, onClose} = props

  const headerBg = useColorModeValue('#dee2e6', 'gray.900')
  const bodyBg = useColorModeValue("#f5f5f5", "gray.800")

  return (
    <>
      <Modal isCentered={true} isOpen={true} onClose={onClose} size='xl'>
        {isLoading?(
          <form.Provider>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                e.stopPropagation()
                void form.handleSubmit()
              }}
            >
              <ModalOverlay>
                <ModalContent bg={bodyBg} overflow='hidden'>
                  <ModalHeader bg={headerBg}>
                    {headerElements.map((element) => (
                      element
                    ))}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <VStack gap={4}>
                      {formFields.map((field) => (
                        field
                      ))}
                    </VStack>
                  </ModalBody>
                  <ModalFooter>
                    <Button mr={3} variant='ghost' onClick={onClose}>
                      Cancel
                    </Button>
                    {SubmitButton}
                  </ModalFooter>
                </ModalContent>
              </ModalOverlay>
            </form>
          </form.Provider>
        ):(
          <ModalContent bg="transparent" shadow="none">
            <Center>
              <Spinner size='lg'/>
            </Center>
          </ModalContent>
        )}
      </Modal>
    </>
  )
}