import { useRef, useState, useContext, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { EquipitUser } from "../models/user";
import { Button } from "@chakra-ui/react";
import { FormControl, Heading, Select, ModalFooter, VStack, useToast, Checkbox, Input, SimpleGrid } from "@chakra-ui/react";
import ResponseHandler from "./Helpers/ResponseHandler";
import { Responses } from "./Helpers/ResponseHandler";
import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList } from "@choc-ui/chakra-autocomplete";
import { dotNetError } from "../models/dotnetDataModels";
import { UserContext } from "../auth/Auth";
import { useFetch } from "../contexts/FetchContext";
import { debounce } from "lodash";
import { useFormKeyBindings } from "./Helpers/useFormKeyBindings";
import AutoExpandingTextarea from "./AutoExpandingTextarea";

interface ItemRentModalProps {
  id?: string;
  onChange: () => void;
}

export const ItemRentModal = (props: ItemRentModalProps) => {
  const { id, onChange } = props;
  const [userList, setUserList] = useState<EquipitUser[]>([]);
  const [selectedOption, setSelectedOption] = useState<EquipitUser | null>(null);
  const [status, setStatus] = useState<number>(1);
  const [note, setNote] = useState<string>("");
  const [external, setExternal] = useState<boolean>(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  
  const selectRef = useRef<HTMLSelectElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const { backend } = useFetch();
  const toast = useToast();

  const fetchData = useCallback(async (query: string) => {
    if (!query) return [];
    try {
      const res = await backend.users.getAll(query);
      return res?.map((user): EquipitUser => ({
        displayName: user.displayName,
        email: user.email,
        company: user.company,
        department: user.department,
        location: user.location,
        telephoneNumber: user.telephoneNumber
      })) || [];
    } catch (error) {
      console.error('Error fetching users:', error);
      return [];
    }
  }, [backend.users]);

  const debouncedFilter = useMemo(
    () => debounce(async (query: string) => {
      const fetchedUsers = await fetchData(query);
      setUserList(fetchedUsers);
    }, 300),
    [fetchData]
  );

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    if (value) {
      debouncedFilter(value);
    } else {
      setUserList([]);
    }
  }, [debouncedFilter]);

  const handleChange = useCallback((selectedOption: EquipitUser) => {
    setSelectedOption(selectedOption);
    setInputValue(selectedOption.displayName);
    setIsSearchFocused(false);
    selectRef.current?.focus();
  }, []);

  const handleRent = useCallback(async () => {
    if (!selectedOption || !userContext || !id) return;

    try {
      await backend.items.rent(
        parseInt(id),
        selectedOption.displayName,
        selectedOption.email,
        selectedOption.company,
        status,
        note
      );
      ResponseHandler("Successfully rented item 🤘", Responses.Success, toast);
      onChange();
      navigate(-1);
    } catch (error) {
      const err = error as dotNetError;
      ResponseHandler(err.response.data.detail, Responses.Error, toast);
      onChange();
    }
  }, [selectedOption, userContext, id, status, note, backend.items, onChange, navigate, toast]);

  const handleExternalUserChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    setSelectedOption({
      displayName: value,
      email: value,
      company: "External",
      department: "External",
      location: "External",
      telephoneNumber: "External"
    });
  }, []);

  useFormKeyBindings({
    handleSubmit: handleRent,
    handleClose: () => navigate(-1),
    canSubmit: selectedOption !== null,
    isSubmitting: false,
  });
  
  const searchComponent = external ? (
    <Input
      autoFocus
      placeholder="External User"
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !selectedOption) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
      value={inputValue}
      onChange={handleExternalUserChange}
    />
  ) : (
    <AutoComplete 
      onSelectOption={(item) => {
        handleChange(item.item.originalValue);
        buttonRef.current?.focus();
      }}
    >
      <AutoCompleteInput
        value={inputValue}
        onChange={handleInputChange}
        autoFocus
        placeholder="Search for user"
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !selectedOption) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      />
      <AutoCompleteList>
        {userList.map((user, index) => (
          <AutoCompleteItem key={index} value={user}>
            {user.displayName}
          </AutoCompleteItem>
        ))}
      </AutoCompleteList>
    </AutoComplete>
  );

  return (
    <VStack align='stretch' spacing={4}>
      <Heading size='md'>Equip to</Heading>
      <Checkbox
        onChange={(e) => setExternal(e.target.checked)}
        isChecked={external}
      >
        External
      </Checkbox>
      <FormControl>
        <SimpleGrid columns={2} spacing={4}>
          {searchComponent}
          <Select
            onChange={(e) => setStatus(parseInt(e.target.value))}
            value={status}
            isRequired
          >
            <option value={1}>Rented</option>
            <option value={0}>Owned</option>
          </Select>
        </SimpleGrid>
      </FormControl>
      <AutoExpandingTextarea
        placeholder="Notes (optional)"
        onChange={(e) => setNote(e.target.value)}
      />
      <ModalFooter>
        <Button
          ref={buttonRef}
          isDisabled={selectedOption === null}
          onClick={handleRent}
          colorScheme="blue"
          size='md'
        >
          Equip
        </Button>
      </ModalFooter>
    </VStack>
  );
};