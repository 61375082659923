import { AxiosResponse } from "axios";
import { Locker } from "../../models/lockers";
import { APIParams, APIType, Method } from "../../contexts/FetchContext";

export const lockersAPI = (API: APIType, URL: string) => {
  const lockerURL = URL + "locker";

  const options = {
    get: async (params?: any) => {
      const APIParams: APIParams = {
        method: Method.get,
        needToken: true,
        URL: lockerURL,
        param: params,
      };
      return await API(APIParams)
        .then((response: AxiosResponse) => {
          return response.data as Locker[];
        })
        .catch((error) => {
          console.log(error);
          throw new Error(error.response?.data?.detail || "Failed to fetch lockers.");
        });
    },
    getById: async (id: number) => {
      const APIParams: APIParams = {
        method: Method.get,
        needToken: true,
        URL: `${lockerURL}/${id}`,
      };
      return await API(APIParams)
        .then((response: AxiosResponse) => {
          return response.data as Locker;
        })
        .catch((error) => {
          console.log(error);
          throw new Error(error.response?.data?.detail || `Failed to fetch locker with ID ${id}.`);
        });
    },
    create: async (locker: Locker) => {
      const APIParams: APIParams = {
        method: Method.post,
        needToken: true,
        URL: lockerURL,
        body: JSON.stringify(locker),
      };
      return await API(APIParams)
        .then((response: AxiosResponse) => {
          return response.data as Locker;
        })
        .catch((error) => {
          console.log(error.response);
          throw new Error(error.response?.data?.detail || "Failed to create locker.");
        });
    },
    update: async (locker: Locker) => {
      const APIParams: APIParams = {
        method: Method.put,
        needToken: true,
        URL: lockerURL,
        body: JSON.stringify(locker),
      };
      return await API(APIParams)
        .then((response: AxiosResponse) => {
          return response.data as Locker;
        })
        .catch((error) => {
          console.log(error);
          throw new Error(error.response?.data?.detail || "Failed to update locker.");
        });
    },
    softDelete: async (id: number) => {
      const APIParams: APIParams = {
        method: Method.delete,
        needToken: true,
        URL: `${lockerURL}/SoftDelete/${id}`,
      };
      return await API(APIParams)
        .then((response: AxiosResponse) => {
          return response.data as Locker;
        })
        .catch((error) => {
          console.log(error);
          throw new Error(error.response?.data?.detail || `Failed to soft delete locker with ID ${id}.`);
        });
    },
    restore: async (id: number) => {
      const APIParams: APIParams = {
        method: Method.post,
        needToken: true,
        URL: `${lockerURL}/Restore/${id}`,
      };
      return await API(APIParams)
        .then((response: AxiosResponse) => {
          return response.data as Locker;
        })
        .catch((error) => {
          console.log(error);
          throw new Error(error.response?.data?.detail || `Failed to restore locker with ID ${id}.`);
        });
    },
  };

  return options;
};