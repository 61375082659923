import { extendTheme, type ThemeConfig } from '@chakra-ui/react'
import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const config: ThemeConfig = {
  initialColorMode: 'system',
  useSystemColorMode: true,
}

const navInput = defineStyle({
  field: {
    background: 'white',
    color: 'black',
    _dark: {
      background: 'whiteAlpha.100',
      color: 'white',
    }
  },
  addon: {
    _dark: {
      _selected: {
        color: 'blue.400'
      }
    }
  }
})

const navButton = defineStyle({
  background: '#e1e1e1',
  color: '#333333',
  justifyContent: 'left',
  borderRadius: '0px',
  _hover: {
    background: '#D6DADE'
  },
  _active: {
    background: '#adb5bd'
  },
  // let's also provide dark mode alternatives
  _dark: {
    background: 'whiteAlpha.100',
    color: 'white',
    
    _hover:{
      background: 'whiteAlpha.300'
    },
    _active: {
      background: 'whiteAlpha.400'
    },
  },
})

const navButtonTheme = defineStyleConfig({
  variants: { navButton: navButton },
})
const navInputTheme = defineStyleConfig({
  variants: { navInput: navInput },
})

const theme = extendTheme({ 
  config, 
  components: {
    Button: navButtonTheme,
    Input: navInputTheme 
  } 
})

export default theme