import { ReactNode, useContext, useEffect, useState } from 'react';
import { Heading, Button, useColorModeValue, Box, Stack, IconButton, Flex } from '@chakra-ui/react';
import { HamburgerIcon, StarIcon, SettingsIcon, ViewIcon, LockIcon, InfoIcon, } from '@chakra-ui/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../../auth/Auth';
import { sideBarLinks } from './NavigationLinks';
import { UserSearch } from '../../pages/User/UserSearch';
import { License } from '../../models/licenses';
import { useFetch } from '../../contexts/FetchContext';
import EnvironmentBadge from '../environments/EnvironmentBadge';

const Sidebar = ({ isCollapsed, onToggleCollapse }: { isCollapsed: boolean, onToggleCollapse: () => void }) => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { licenseIt } = useFetch();
  const [licenses, setLicenses] = useState<License[]>([]);

  useEffect(() => {
    licenseIt.licenses().then((licenses) => {
      setLicenses(licenses);
    }).catch((err) => {
      console.log(err);
    });
  }, [user]);

  const bg = useColorModeValue('#e9e9e9', 'gray.900');

  const navButton = (path: string, title: string, index: number) => {
    return (
      <Button 
        key={index} 
        onClick={() => navigate(path)} 
        isActive={decodeURI(location.pathname).startsWith(path)} 
        variant='navButton'
        display={isCollapsed ? 'none' : 'flex'}
      >
        {title}
      </Button>
    );
  };

  const HeadingWithIcon = ({ title, icon, size = 'xs' }: { title: string, icon: ReactNode, size?: string }) => (
    <Flex 
      align="center" 
      pl={4} 
      mr={4} 
      cursor={isCollapsed ? 'pointer' : 'default'}
      onClick={isCollapsed ? onToggleCollapse : undefined}
    >
      {icon}
      <Heading size={size} ml={2} display={isCollapsed ? 'none' : 'block'}>
        {title}
      </Heading>
    </Flex>
  );

  const PageTitle = () => (
    <Box
      as="button"
      onClick={() => navigate('/')}
      w='100%'
      mb={9}
      display={isCollapsed ? 'none' : 'block'}
      _hover={{ color: 'blue.400' }}
      transition="color 0.2s"
    >
      <Heading
        size="2xl"
        textAlign="center"
        whiteSpace="nowrap"
      >
        EquipIt
      </Heading>
    </Box>
  );

  const navList = () => {
    return (
      <>
        {user && user.role !== 'User' ? (
          <>
            {!isCollapsed && <UserSearch />}
            <HeadingWithIcon title="POLICY" icon={<LockIcon />} />
            <Stack direction='column' spacing={0} borderRadius={6} overflow='hidden'>
              {sideBarLinks.admin.policy.map((element, index) => {
                if(element.alloweduser.includes(user.role)){
                  return navButton(element.path, element.title, index);
                }
              })}
            </Stack>
            <HeadingWithIcon title="EQUIPMENT" icon={<SettingsIcon />} />
            <Stack direction='column' spacing={0} borderRadius={6} overflow='hidden'>
              {sideBarLinks.admin.equipment.map((element, index) => {
                if(element.alloweduser.includes(user.role)){
                  return navButton(element.path, element.title, index);
                }
              })}
            </Stack>
            <HeadingWithIcon title="OTHER" icon={<InfoIcon />} />
            <Stack direction='column' spacing={0} borderRadius={6} overflow='hidden'>
              {sideBarLinks.admin.other.map((element, index) => {
                if(element.alloweduser.includes(user.role)){
                  return navButton(element.path, element.title, index);
                }
              })}
            </Stack>
            <HeadingWithIcon title="LICENSES" icon={<LockIcon />} />
            <Stack direction='column' spacing={0} borderRadius={6} overflow='hidden'>
              {sideBarLinks.admin.licenses.map((element, index) => {
                if(element.alloweduser.includes(user.role)){
                  if (element.customComponent) {
                    return !isCollapsed && element.customComponent;
                  }
                  return navButton(element.path, element.title, index);
                }
              })}
            </Stack>
          </>
        ) : null}
      </>
    );
  };

  return (
    <Box
      bg={bg}
      shadow='md'
      p={4}
      pt={20}
      h='100%'
      overflow='auto'
      transition="all 0.3s ease"
      position="relative"
    >
      <IconButton
        aria-label="Toggle Sidebar"
        icon={<HamburgerIcon />}
        onClick={onToggleCollapse}
        position="absolute"
        top={4}
        left={4}
        size="md"
      />
      
      <EnvironmentBadge />
      <PageTitle />

      <Stack direction='column' spacing={5}>
        {navList()}
      </Stack>
    </Box>
  );
};

export default Sidebar;