import { useContext, useEffect, useState } from 'react';
import { 
  Heading,
  useColorMode, 
  useColorModeValue,
  Stack,
  Grid,
  GridItem,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  IconButton,
  Avatar,
  useMediaQuery,
  HStack,
  Box,
} from '@chakra-ui/react'
import {SunIcon, LockIcon, MoonIcon, SettingsIcon, } from '@chakra-ui/icons'
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../auth/Auth';
import { useMsal } from '@azure/msal-react';
import { AppContext } from '../contexts/AppContext';

export const Header = () => {
  const navigate = useNavigate();
  const {instance} = useMsal();
  const app = useContext(AppContext)
  const { toggleColorMode } = useColorMode()
  const [pageTitle, setPageTitle] = useState<string>('Title')
  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)')
  const user = useContext(UserContext);
  const [isNameClicked, setIsNameClicked] = useState(false);

  useEffect(() => {
    setPageTitle(app.title)
    document.title = app.title
  }, [app]);

  const colorMode = useColorMode()

  const logout = () => {
    instance.logoutRedirect({ account: instance.getActiveAccount() });
  }

  const handleClick = () => {
    setIsNameClicked(true);
    navigate('/equipment');
    // Reset the animation after 500ms
    setTimeout(() => setIsNameClicked(false), 500);
  };

  const bg = useColorModeValue('#f1f1f1', 'gray.900')

  const colorModeIcon = () => {
    if (colorMode.colorMode == 'dark'){
      return <SunIcon/>
    }
    return <MoonIcon/>
  }

  const nameAndAvatarContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '2',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    _hover: {
      '& .user-name': {
        color: 'blue.400',
        transform: 'scale(1.02)',
      },
      '& .user-avatar': {
        transform: 'scale(1.05)',
      }
    }
  }

  return (
    <Grid
      h='100%'
      pl={8}
      pr={4}
      pt={16}
      pb={8}
      gap={4}
      templateAreas={`
        "title settings"`}
      gridTemplateRows={'1fr'}
      gridTemplateColumns={'1fr auto'}
      alignItems='center'
    >
      <GridItem area={'title'}>
        <Heading>{pageTitle}</Heading>
      </GridItem>
      <GridItem area={'settings'}>
        <Stack direction='row' alignItems='center' borderRadius={6} bg={bg} shadow='md' p={3}>
          {isLargerThan1280 && (
            <Box
              as="div"
              onClick={handleClick}
              sx={nameAndAvatarContainerStyle}
            >
              <Heading 
                size='xs'
                className="user-name"
                color={isNameClicked ? 'blue.500' : 'inherit'}
              >
                {user?.name}
              </Heading>
              <Avatar 
                name={user?.name} 
                size='sm' 
                src='' 
                className="user-avatar"
                transition="transform 0.2s ease-in-out"
              />
            </Box>
          )}
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label='Options'
              icon={<SettingsIcon />}
              variant='ghost'
            />
            <MenuList>
              {!isLargerThan1280 && (
                <HStack
                  p={4}
                  w={'100%'}
                  alignItems={'center'}
                  justify={'space-between'}
                >
                  <Box
                    as="div"
                    onClick={handleClick}
                    sx={nameAndAvatarContainerStyle}
                  >
                    <Heading 
                      size='sm'
                      className="user-name"
                      color={isNameClicked ? 'blue.500' : 'inherit'}
                    >
                      {user?.name}
                    </Heading>
                    <Avatar 
                      name={user?.name} 
                      size='md' 
                      src='' 
                      className="user-avatar"
                      transition="transform 0.2s ease-in-out"
                    />
                  </Box>
                </HStack>
              )}
              <MenuItem icon={colorModeIcon()} onClick={toggleColorMode}>
                {colorMode.colorMode == 'dark' ? "Switch to Light Mode" : "Switch to Dark Mode"}
              </MenuItem>
              <MenuItem icon={<SettingsIcon />}>
                Settings
              </MenuItem>
              <MenuItem onClick={logout} icon={<LockIcon />}>
                Log Out
              </MenuItem>
            </MenuList>
          </Menu>
        </Stack>
      </GridItem>
    </Grid>
  )
}

export default Header;