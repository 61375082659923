import { Button, FormLabel, HStack, Heading, IconButton, Input, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Manufacturer, Model } from "../../models/manufacturer";
import { useForm } from '@tanstack/react-form'
import { FormControl } from '@chakra-ui/react'
import { useFetch } from "../../contexts/FetchContext";
import { FormModal } from "../../components/Helpers/FormModal";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { useFormKeyBindings } from "../../components/Helpers/useFormKeyBindings";

export const EditManufacturer = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [manufacturer, setManufacturer] = useState<Manufacturer>();
  const [deletedModels, setDeletedModels] = useState<Model[]>([]);

  const { id } = useParams();
  const navigate = useNavigate();
  const { backend } = useFetch();


  const refetch = () => {
    if (id) {
      setLoading(true)
      backend.manufacturers.getById(parseInt(id))
      .then((data) => {
        setManufacturer(data);
      }).catch((err) => {
        console.log(err)
        onClose()
      })
    }
  }

  useEffect(() => {
    refetch()
  }, []);

  const onClose = () => {
    navigate(-1);
};

  const form = useForm({
    defaultValues: {
      name: manufacturer?.name || '',
      models: manufacturer?.models || [],
    },
    onSubmit: async (values) => {
      if (await form.validateAllFields("submit") && manufacturer) {
        const updatedManufacturer: Manufacturer = {
          id: manufacturer.id,
          name: values.name!,
          models: values.models!,
        }
        deletedModels.forEach((model) => {
          backend.manufacturers.deleteModel(model.id)
        });
        backend.manufacturers.update(updatedManufacturer)
        .then((response) => {
          onClose();
        }).catch((error) => {
          console.log(error)
        });
      }
    }
  })

  useFormKeyBindings({
    handleSubmit: form.handleSubmit,
    handleClose: onClose,
    canSubmit: form.state.canSubmit,
    isSubmitting: form.state.isSubmitting
  });


  const FormFields = [
    (
      <form.Field
        name="name"
        onChange={(value) => {
          return !value || value.length === 0
            ? 'Name is required'
            : undefined
        }}
        children={field => {
          return(
            <FormControl isRequired isInvalid={field.state.meta.errors.length > 0}>
              <FormLabel>Name</FormLabel>
              <Input autoFocus variant='filled' required name={field.name} value={field.state.value} onBlur={field.handleBlur} onChange={(e) => field.handleChange(e.target.value)} placeholder="Name"/>
            </FormControl>
          )
        }}
      />
    ),(
      <form.Field
        name="models"
        onChange={(value) => {
          return !value || value.length === 0
            ? 'Name is required'
            : undefined
        }}
        children={field => {
          return (
            <FormControl isRequired isInvalid={field.state.meta.errors.length > 0}>
              <VStack alignItems='stretch'>
                <FormLabel>Models</FormLabel>
                {field.state.value.map((model: Model, index: number) => {
                  return(
                    <HStack key={index} justifyContent='space-between' alignItems='center'>
                      <Input variant='filled'
                        required name={field.name}
                        value={model.name}
                        onBlur={field.handleBlur}
                        isDisabled={deletedModels.find((deletedModel) => deletedModel.id === model.id) ? true : false}
                        style={{textDecoration: deletedModels.find((deletedModel) => deletedModel.id === model.id) ? 'line-through' : 'none'}}
                        onChange={(e) => {
                          const newModels = [...field.state.value];
                          newModels[index] = { id: newModels[index].id, name: e.target.value } as Model;
                          field.setValue(newModels);
                        }} placeholder="Name" />
                      <IconButton aria-label="remove" icon={<MinusIcon />} onClick={() => {
                        {/* no need to add to deletedModels if it's a new model that is not in the database */ }
                        if (model.id !== 0) {
                          setDeletedModels([...deletedModels, model]);
                        } else {
                          const newModels = [...field.state.value];
                          newModels.splice(index, 1);
                          field.setValue(newModels);
                        }
                      }} />
                    </HStack>
                  )
                })}
                <HStack justifyContent='end'>
                  <IconButton aria-label="add" icon={<AddIcon/>} onClick={() => {
                    const newModels = [...field.state.value];
                    newModels.push({id: 0, name: ''} as Model);
                    field.setValue(newModels);
                  }}/>
                </HStack>
              </VStack>
            </FormControl>
          )
        }}
      />
    )
  ]

  const SubmitButton = () => {
    return(
      <Button colorScheme='blue' isLoading={form.state.isSubmitting} type="submit" disabled={!form.state.canSubmit}>
        Update
      </Button>
    )
  }

  return (
    <FormModal
      isLoading={manufacturer ? true : false}
      headerElements={[<Heading size='xl' mt={5}>Update manufacturer {manufacturer?.name}</Heading>]}
      form={form}
      formFields={FormFields}
      SubmitButton={<SubmitButton/>}
      onClose={onClose}
    />
  )
}
