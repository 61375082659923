import { computersAPI } from "./computers";
import { APIType } from "../../contexts/FetchContext";
import { itemAPI } from "./item";
import { manufacturersAPI } from "./manufacturers";
import { keycardsAPI } from "./keycards";
import { userAPI } from "./user";
import { licenseGroups } from "./licenseGroups";
import { licenses } from "./licenses";
import { jetBrainsLicenses } from "./jetBrainsLicense";
import { roleAPI } from "./roles";
import { permissionAPI } from "./permissions";
import { lockersAPI } from "./lockers";

export const backendAPI = (API: APIType) => {
  const URL: string = `${process.env["REACT_APP_EQUIPIT_BACKEND"]}`;
  const options = {
    computers: computersAPI(API, URL),
    keycards: keycardsAPI(API, URL),
    licenseGroups: licenseGroups(API, URL),
    licenses: licenses(API, URL),
    jetBrainsLicenses: jetBrainsLicenses(API, URL),
    items: itemAPI(API, URL),
    manufacturers: manufacturersAPI(API, URL),
    lockers: lockersAPI(API, URL),
    users: userAPI(API, URL),
    role: roleAPI(API, URL),
    permission: permissionAPI(API, URL),
  }
  return options;
}
