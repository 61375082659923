import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetch } from '../../contexts/FetchContext';
import { useForm } from '@tanstack/react-form';
import { FormModal } from '../../components/Helpers/FormModal';
import { Button, Heading, useToast, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useFormKeyBindings } from '../../components/Helpers/useFormKeyBindings';

export const CreateRole = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { backend } = useFetch();
  const navigate = useNavigate();
  const toast = useToast();

  const onClose = () => navigate(-1);

  const form = useForm({
    defaultValues: {
      roleName: '',
    },
    onSubmit: async (values) => {
      if (await form.validateAllFields("submit")) {
        setIsLoading(false);
        try {
          await backend.role.createRole(values.roleName);
          toast({
            title: 'Role created',
            description: `The role "${values.roleName}" has been successfully created.`,
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          navigate(-1);
        } catch (error) {
          toast({
            title: 'Error creating role',
            description: 'There was an error creating the role. Please try again.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setIsLoading(true);
        }
      }
    }
  });


  useFormKeyBindings({
    handleSubmit: form.handleSubmit,
    handleClose: onClose,
    canSubmit: form.state.canSubmit,
    isSubmitting: form.state.isSubmitting
  });


  const FormFields = [
    <form.Field
      name="roleName"
      onChange={(value) => {
        return !value || value.length === 0
          ? 'Role name is required'
          : undefined
      }}
    >
      {(field) => (
        <FormControl isInvalid={field.state.meta.errors.length > 0}>
          <FormLabel>Role Name</FormLabel>
          <Input
            autoFocus
            variant='filled'
            name={field.name}
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.target.value)}
            placeholder="Enter role name"
            required
          />
        </FormControl>
      )}
    </form.Field>
  ];

  const SubmitButton = () => (
    <Button colorScheme='blue' isLoading={form.state.isSubmitting} type="submit" disabled={!form.state.canSubmit}>
      Create Role
    </Button>
  );

  const Header = () => (
    <Heading size='xl' mt={5}>Create New Role</Heading>
  );


  return (
    <FormModal
      isLoading={isLoading}
      headerElements={[<Header />]}
      form={form}
      formFields={FormFields}
      SubmitButton={<SubmitButton />}
      onClose={onClose}
    />
  );
};