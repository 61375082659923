import { ButtonGroup, Heading, IconButton, Stack, Tag, Tooltip } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { InitialTableState, ColumnDef } from "@tanstack/react-table";
import { TanstackTable } from "../../components/TanstackTable";
import { Locker } from "../../models/lockers";


type UserLockersProps = {
  email: string,
  lockers: Locker[]
}

export const UserLockers = ({email, lockers}: UserLockersProps) => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<Locker[]>([]);
  const location = useLocation();

  useEffect(() => {
    setTableData(lockers)
  }, [lockers])

  const initialTableState: InitialTableState = {
    sorting: [{ id: 'lockerNumber', desc: false }],
    columnVisibility: {
      'keyNumber': false
    }
  }

  const buttonGroup = (id: number) => {
    return(
      <ButtonGroup variant="ghost">
        <Tooltip label="Return Key">
          <IconButton
            aria-label="return"
            icon={<KeyboardReturnIcon />}
            onClick={(event) => {
              event.stopPropagation();
              navigate(`locker/${id}/return`, { state: { previousLocation: location.pathname} })
            }}
          />
        </Tooltip>
      </ButtonGroup>
    )
  }

  const statusTags = (status: number | undefined) => {
    if (status != undefined) {
      switch (status) {
        case 0:
          return <Tag colorScheme='red'>Owned</Tag>
        case 1:
          return <Tag colorScheme='orange'>Rented</Tag>
        default:
          return <Tag colorScheme='green'>Available</Tag>
      }
    }
    return <Tag colorScheme='green'>Available</Tag>
  }

  const onRowClick = (item: Locker) => {
    navigate(`locker/${item.id}`, { state: { previousLocation: location.pathname } })
  }

  const columns: ColumnDef<Locker>[] = [
    {
      id: 'lockerNumber',
      accessorFn: row => row.lockerNumber,
      sortingFn: 'alphanumeric',
      enableHiding: true,
      header: 'Locker Number',
      cell: info => info.getValue(),
    },
    {
      id: 'location',
      accessorFn: row => row.lockerLocation,
      sortingFn: 'alphanumeric',
      enableHiding: true,
      header: 'Location',
      cell: info => info.getValue(),
    },
    {
      id: 'status',
      accessorFn: row => row.rentStatus?.status ?? 3,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'Status',
      cell: info => statusTags(info.row.original.rentStatus?.status),
    },
    {
      id: 'actions',
      header: 'Actions',
      size: 10,
      enableHiding: false,
      maxSize: 10,
      cell: info => buttonGroup(info.row.original.id),
      meta: {
        isNumeric: true
      }
    },
  ]

  return (
    <Stack spacing='4'>
      <Heading marginLeft='4' size='lg'>Lockers</Heading>
      <TanstackTable
        columns={columns}
        data={tableData}
        initialTableState={initialTableState}
        onRowClick={(item: Locker) => onRowClick(item)}
        onSortingChange={(newSorting) => console.log(newSorting)}
        isLoading={false}       
        storageKey='userLockerTableVisibility'/>
    </Stack>
  )
}